<template>
    <el-table v-if="dataOrigin.length > 0" v-loading="$wait.is('loading keys')" :data="data" style="width: 100%">
        <el-table-column prop="name" :label="$t('keys.name')" sortable />
        <el-table-column prop="description" :label="$t('keys.description')" sortable :show-overflow-tooltip="true" />
        <el-table-column prop="client" :label="$t('keys.client')" sortable :show-overflow-tooltip="true" />
        <el-table-column prop="address" :label="$t('keys.address')" sortable :show-overflow-tooltip="true" />
        <el-table-column prop="holder" :label="$t('keys.holds')" sortable :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link
                    v-if="scope.row.holder_uuid"
                    :to="`/employees/${scope.row.holder_uuid}/information`"
                    class="hover:text-red-500 hover:font-semibold"
                >
                    {{ currentHolderFormatter(scope.row.holder) }}
                </router-link>
                <p v-else>
                    {{ currentHolderFormatter(scope.row.holder) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column width="110">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('keys.history')" placement="left">
                        <el-button type="primary" size="mini" @click="handleKeyHistory(scope)">
                            <fa-icon :icon="['far', 'history']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('keys.bring_back')" placement="top">
                        <el-button type="warning" size="mini" :disabled="!userCan('update keys')" @click="handleRestore(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'redo']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                        <el-button type="danger" size="mini" :disabled="!userCan('delete keys')" @click="handleDelete(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'trash']" />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },

        dataOrigin: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        currentHolderFormatter(holder) {
            if (holder === 'office' || holder === 'client') {
                return this.$t(`keys.at_${holder}`);
            }
            return holder;
        },

        handleKeyHistory(value) {
            this.$emit('handleKeyHistory', value);
        },

        handleRestore(value) {
            this.$emit('handleRestore', value);
        },

        handleDelete(value) {
            this.$emit('handleDelete', value);
        },
    },
};
</script>
